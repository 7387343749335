input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #333333 !important;
}

ion-item{
  --background-focused: transparent
}

.ion-color-icon {
	--ion-color-base: var(--ion-color-icon);
	--ion-color-base-rgb: var(--ion-color-icon-rgb);
	--ion-color-contrast: var(--ion-color-icon-contrast);
	--ion-color-contrast-rgb: var(--ion-color-icon-contrast-rgb);
	--ion-color-shade: var(--ion-color-icon-shade);
	--ion-color-tint: var(--ion-color-icon-tint);
}

.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}


ion-button {
  // --border-radius: 7px;
  // font-weight: 500;
}

ion-toolbar {
  // --background: #ffffff;
  // --padding-end: 20px;
  // --padding-start: 20px;
}

.white-toolbar {
  --background: #ffffff;
}

.flex {
  display: flex;

  &-column {
    flex-direction: column;
  }
}

.text-sm {
  font-size: 13px !important;
}

.text-normal {
  font-size: 14px !important;
}

.item-title {
  font-size: 13px !important;
  font-weight: 300 !important;
  color: rgba(255, 255, 255, 0.8) !important;
  --color: rgba(255, 255, 255, 0.8) !important;
}

.item-value {
  font-size: 14px;
  font-weight: bold;
}

.font-bold {
  font-weight: bold;
}

.stepper-title {
  font-size: 25px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
}

.stepper-bigtext {
  font-weight: 500;
  font-size: 16px;
  margin-top: 0;
}

.stepper-content {
  // height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  // padding-bottom: 20px;
}

.stepper-progress-btn {
  // width: 45%;
  margin-top: auto;

  &::part(native) {
    padding-right: 30px;
    padding-left: 30px;
    text-transform: capitalize;
    font-weight: 600;
  }
}

.form-item {
  --border-width: 0;
  --border-color: none;
  --background: #f1f4f5;
  --border-radius: 5px;
  --min-height: 45px
}

ion-select {
  width: 100%;
  // color: #a1abb4;
  // --placeholder-color: #a1abb4;
  --placeholder-opacity: 1;
}

.form-input {
  // color: #a1abb4;
  // --placeholder-color: #a1abb4;
  margin-top: 0;
}

.dashboard-cta {
  // height: 100px;
  height: 85px;
  cursor: pointer;
  width: calc((100% / 2) - 3px);
  border-radius: 5px;
  // color: #ffffff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba($color: #1daba7, $alpha: .3);

  &:nth-of-type(2) {
    background-color: rgba($color: #80d9ff, $alpha: .3)
  }

  &:nth-of-type(3) {
    // background-color: #f56c2a !important;
    background-color: rgba($color: #80d9ff, $alpha: .3)
  }


  &-icon {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // color: #ffffff;
    background-color: #ffffff;
    // &.orange-icon {
    //     background-color: rgba(245, 108, 42, 1) !important;
    // }

    // &.blue-icon {
    //     background-color: rgba(0, 46, 91, 1);
    // }
  }

  // span {
  //     marg
  // }
}

.stepper-heading {
  font-size: 25px;
  font-weight: 500;
  margin: 0;
}

.stepper-steps {
  display: flex;
  // margin: 20px 0;
  // width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  // justify-content: center;
}

.stepper-step {
  width: 8px;
  height: 8px;
  // background-color: #C4C4C4;
  background-color: rgba(205, 217, 227, 0.99);
  display: inline-block;
  border-radius: 4px;
  transition: all .3s ease-in-out;

  &:not(:last-of-type) {
    margin-right: 15px;
  }

  &.activestep {
    background-color: #F56C2A;
    // width: calc((100% / 5) - 3px);
  }

  &.currentstep {
    width: 30px;
  }
}

.loan-success {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  &-text {
    // font-size: 18px;
    font-weight: 500;
    margin: 18px 0;
    text-align: center;
  }

  &-btn {
    width: 75%;
  }
}

.remove-focus {
  cursor: pointer;
  &:focus,
  &:active {
    border: 0;
    outline: 0;
  }
}

.account-btn {
  background: rgba(251, 230, 221, 1);
   width: 45px;
   height: 45px;
   border-radius: 100%;

   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px;
   margin: auto;
   margin-bottom: 10px;
 }

ion-grid.quicklinks {
 padding-top: 15px;
 padding-bottom: 15px;
 background: rgba(245, 245, 245, 0.5);
 border-radius: 5px;
}


.shapes-bg::part(scroll) {
  background-image: url('/assets/images/bubbles-few-bg.svg'), url('/assets/images/ellipse-bg.svg');
  background-position-y: 0%, 100%;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
}

ion-input {
  --background: transparent
}

.transparent-toolbar {
  --background: transparent
}

.add-white-bg {
  --background: rgba(255, 255, 255, 0.5);
  transition: all .2s ease-in-out;
}

.notification-btn {
  height: 40px !important;
    width: 40px !important;
    --background: rgba(254, 245, 240, 1) !important;
    --border-radius: 100% !important;
}
